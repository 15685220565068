<template>
  <div class="page more-depth">
    <div class="container">
      <!-- <div class="page-header">
        <div class="page-header__title">
          {{ ($__t('이메일') + ' ' + $__t('회원가입')) | capitalizeFirstLetter }}
        </div>
      </div> -->
      <div class="page-body">
        <ui-form ref="form" :formData="formData" @submit="submitForm" :ignoreSubmit="true">
          <ui-form-list>
            <!--이름-->
            <ui-form-list-item>
              <div class="title">{{ $__t('Full Name') }}</div>
              <lug-text-input
                v-model="formData.user_name.value"
                :placeholder="formData.user_name.placeholder"
                :error="formData.user_name.error"
                :type="formData.user_name.type"
              />
              <div class="noDataAlert" style="color: rgb(230, 20, 20); font-size: 0.875rem" v-if="noNameError">{{ $__t('이름을 입력해주세요') }}</div>
            </ui-form-list-item>
            <!--휴대폰 번호 입력-->
            <ui-form-list-item>
              <div class="title">{{ $__t('휴대폰 번호') }}</div>
              <div class="phoneNum-requestCert">

                <lug-select
                  :placeholder="formData.user_dial_code.placeholder"
                  :type="formData.user_dial_code.type"
                  :items="formData.user_dial_code.items"
                  v-model="formData.user_dial_code.value"
                  :disabled="false"
                />

                <lug-text-input
                  :placeholder="formData.user_phone.placeholder"
                  :type="formData.user_phone.type"
                  :inputmode="formData.user_phone.input_mode"
                  v-model="formData.user_phone.value"
                  :mask="formData.user_phone.mask"
                />

                <button
                  class="btn-certify"
                  :class="{ inactivated: !certnumRequestActivated || alreadyRequestNum }"
                  v-ripple="'rgba( 162, 190, 218, 0.35 )'"
                  @click.prevent="onClickRequestCertNum()"
                >
                  {{ $__t('인증요청') }}
                </button>
              </div>
            </ui-form-list-item>
            <!--인증번호 입력-->
            <ui-form-list-item>
              <div class="title">{{ $__t('인증번호') }}</div>
              <div class="certNum-timer-resend">
                <lug-text-input
                  :placeholder="formData.user_cert_num.placeholder"
                  :type="formData.user_cert_num.type"
                  :inputmode="formData.user_cert_num.input_mode"
                  v-model="formData.user_cert_num.value"
                  :required="false"
                  :mask="formData.user_cert_num.mask"
                ></lug-text-input>
                <div class="timerandresend" :class="{ activated: resendAndTimerActivated }">
                  <span class="timer">
                    <certnum-timer :start="rerender" />
                  </span>
                  <span class="divider">|</span>
                  <button
                    class="btn-recertify"
                    :disabled="!resendAndTimerActivated"
                    @click.prevent="onClickRequestCertNum()"
                    v-ripple="'rgba( 162, 190, 218, 0.35 )'"
                  >
                    {{ $__t('재전송') }}
                  </button>
                </div>
              </div>
              <div v-if="invalidCodeError" class="certNumAlert" style="color: rgb(230, 20, 20); font-size: 0.875rem">
                {{ $__t('인증번호를 확인해주세요') }}
              </div>
              <div v-else-if="expiredCodeError" class="certNumAlert" style="color: rgb(230, 20, 20); font-size: 0.875rem">
                {{ $__t('인증번호 입력시간을 초과하였습니다') }}
              </div>
            </ui-form-list-item>
            <!--이메일-->
            <!-- <ui-form-list-item>
              <lug-text-input
                v-model="formData.account.value"
                :label="formData.account.label"
                :placeholder="formData.account.placeholder"
                :error="formData.account.error"
                :type="formData.account.type"
              />
            </ui-form-list-item> -->
            <!--비밀번호-->
            <!-- <ui-form-list-item>
              <lug-text-input
                v-model="formData.password.value"
                :label="formData.password.label"
                :placeholder="formData.password.placeholder"
                :error="formData.password.error"
                :type="formData.password.type"
              />
            </ui-form-list-item> -->
            <!--비밀번호 확인-->
            <!-- <ui-form-list-item>
              <lug-text-input
                v-model="formData.password_confirm.value"
                :label="formData.password_confirm.label"
                :placeholder="formData.password_confirm.placeholder"
                :error="formData.password_confirm.error"
                :type="formData.password_confirm.type"
              />
            </ui-form-list-item> -->
          </ui-form-list>
        </ui-form>
      </div>

      <div class="page-footer">
        <button
          type="button"
          v-button
          @click="onClickSubmit()"
          class="ui-submit-button"
          :disabled="!formActivated"
          :class="{ activated: formActivated }"
        >
          <span class="ui-submit-button__text">
            {{ $__t('회원가입') }}
          </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import CertnumTimer from '@/components/pages/service-product/utils/CertnumTimer.vue';
import countryCodes from '@/assets/json/country-codes.json';

export default {
  components: {
    CertnumTimer
  },
  data() {
    return {
      rerender: 1,
      invalidCodeError: false,
      expiredCodeError: false,
      resendAndTimerActivated: false,
      alreadyRequestNum: false,
      noNameError: false,
      formData: {
        // account: {
        //   type: 'text',
        //   validation: [{ type: 'required' }, { type: 'email' }],
        //   value: '',
        //   label: this.$__t('이메일'),
        //   placeholder: this.$__t('이메일 입력'),
        //   error: ''
        // },

        // password: {
        //   type: 'password',
        //   validation: [{ type: 'required' }, { type: 'passwordLength' }],
        //   value: '',
        //   label: this.$__t('비밀번호'),
        //   placeholder: this.$__t("'-' 제외하고 번호만 입력해주세요"),
        //   error: ''
        // },

        // password_confirm: {
        //   type: 'password',
        //   validation: [{ type: 'required' }, { type: 'matchField:password' }],
        //   value: '',
        //   label: this.$__t('비밀번호 확인'),
        //   placeholder: this.$__t('8~20자'),
        //   error: ''
        // },
        user_dial_code: {
          value: '+82/KR',
          error: '',
          validation: [{ type: 'required' }],
          items: this.parseCountryCodes(countryCodes),
          label: this.$__t('국가 번호'),
          placeholder: '국가번호'
        },

        user_name: {
          type: 'text',
          validation: [{ type: 'required' }],
          value: '',
          label: this.$__t('이름'),
          placeholder: this.$__t('이름을 입력해주세요'),
          error: ''
        },

        user_phone: {
          type: 'text',
          label: this.$__t('휴대폰 번호'),
          placeholder: this.$__t("'-'제외하고 번호만 입력해주세요"),
          validation: [{ type: 'required' }, { type: 'number' }],
          input_mode: 'numeric',
          error: '',
          value: '',
          mask: '###-####-####',
          cert_number: '',
          verifiing: false,
          need_to_retry: false,
          auth_key: null
        },

        user_cert_num: {
          type: 'text',
          label: this.$__t('인증번호'),
          placeholder: this.$__t('인증번호 6자리를 입력해주세요'),
          // validation: [{ type: 'number' }],
          error: '',
          input_mode: 'numeric',
          value: '',
          mask: '######'
        }
      }
    };
  },

  computed: {
    formActivated() {
      // console.log('formactivated');
      return Object.keys(this.formData).every((o) => !!this.formData[o].value);
    },

    isViewTypeIez() {
      return this.$store.getters['userData/GET_ITEM']({ name: '__LUGSTAY__VIEW__TYPE__', noDefault: true }) === 'iez';
    },
    certnumRequestActivated() {
      if (this.formData.user_dial_code.value === '+82/KR') {
        if (this.formData?.user_phone?.value?.length === 11) {
          return true;
        }
        return false;
      } else {
        if (this.formData?.user_phone?.value?.length > 0) {
          return true;
        }
        return false;
      }
    }

    // campaignCode() {
    //   return this.$store.getters['userData/GET_ITEM']({ key: 'campaignCode' });
    // },

    // promotion() {
    //   return this.$store.getters['userData/GET_ITEM']({ key: 'promotion' });
    // }
  },

  watch: {
    formData: {
      deep: true,

      handler() {
        // console.log('ddd', this.formData.user_dial_code.value === '+82', this.formData.user_phone.mask);
        if (this.formData.user_dial_code.value === '+82/KR') {
          return (this.formData.user_phone.mask = '###-####-####');
        } else {
          return (this.formData.user_phone.mask = null);
        }
      }
    }
  },

  methods: {
    onClickSubmit() {
      this.$refs.form.submit()
    },

    parseCountryCodes(countryCodes) {
      let _dial_code =
        countryCodes.map((o) => ({
          label: `${o.dial_code} (${o.code})`,
          // value: o.dial_code
          value: o.dial_code + '/' + o.code
        })) || [];
      _dial_code.sort((a, b) => (a.value < b.value ? -1 : a.value > b.value ? 1 : 0));
      _dial_code.forEach((o) => {
        //
        if (o.name === 'Korea, Republic of') {
          _dial_code.splice(_dial_code.indexOf(o), 1);
          _dial_code.unshift(o);
        }
        //
        if (o.name === 'Others') {
          _dial_code.splice(_dial_code.indexOf(o), 1);
          _dial_code.push(o);
        }
      });

      return _dial_code;
    },
    //here
    async onClickRequestCertNum(e) {
      
      this.invalidCodeError = false;
      this.expiredCodeError = false;
      this.noNameError = false;
      this.formData.user_cert_num.value = '';
      
      if (this.formData.user_name.value === '') {
        this.noNameError = true;
        this.alreadyRequestNum = false;
        return false;
      }

      if (this.formData.user_phone.value === '') {
        this.noNameError = false;
        this.alreadyRequestNum = false;
        return false;
      }

      this.alreadyRequestNum = true;

      try {
        this.formData.user_phone.verifiing = true;

        let res;

        res = await this.$store.dispatch('auth/authNumRequest', {
          user_name: this.formData.user_name.value,
          phone: this.formData.user_phone.value,
          user_dial_code: this.formData.user_dial_code.value.split('/')[0],
          user_iso_code: this.formData.user_dial_code.value.split('/')[1]
        });

        if (this.$store.state.config.mode === 'debug') {
          const { auth_code } = res;
          setTimeout(() => {
            this.formData.user_cert_num.value = auth_code;
          }, 100);
        }

        this.rerender = this.rerender + 1;
        this.formData.user_phone.auth_key = res.auth_key;
        this.formData.user_phone.need_to_retry = true;
        this.resendAndTimerActivated = true;
      } catch (e) {
        console.error(e);

        this.alreadyRequestNum = false;
        this.$store.commit('alert/ADD_ITEM', {
          message: e,
          status: 'error'
        });
      } finally {
        this.formData.user_phone.verifiing = false;
      }

      return true;
    },

    async submitForm(values) {
      if (!this.formActivated) {
        return false;
      }

      this.invalidCodeError = false;
      this.expiredCodeError = false;
      try {
        this.$store.commit('loading/SET_TRUE');

        let { user_phone } = values || {};

        let payload = {
          user_phone,
          auth_key: this.formData.user_phone.auth_key,
          auth_code: this.formData.user_cert_num.value
        };

        let registerResult = await this.$store.dispatch('auth/register', payload);
        // console.log('registerResult', registerResult);

        //1. 인증번호 틀렸을떄
        //data.data.message === 'invalid_code'
        if (registerResult.data.data.message === 'invalid_code') {
          this.invalidCodeError = true;
          setTimeout(() => {
            this.$store.commit('loading/SET_FALSE');
          }, 500);
          return;
          //2. 3분 초과되었을때
          //data.data.message === 'expired_key;
        } else if (registerResult.data.data.message === 'expired_key') {
          this.expiredCodeError = true;
          setTimeout(() => {
            this.$store.commit('loading/SET_FALSE');
          }, 500);
          return;
        }
        //3. 이미 가입된 유저일 경우
        if (registerResult.data.status === 'error') {
          alert('이미 가입된 유저입니다. 로그인하세요.');
          this.$router.push({ name: 'AuthLogin', query: this.$route.query }).catch(() => {
            alert('잘못된 접근');
          });
          setTimeout(() => {
            this.$store.commit('loading/SET_FALSE');
          }, 500);
          return;
        }
        //회원가입에 성공했을 경우
        // await this.$store.dispatch('auth/login', payload);

        this.$analytics.logEvent('sign up complete', { provider: 'Email' });
        this.$analytics.logEvent('login complete', { provider: 'Email' });
        this.$analytics.tracking.call(this, 'session', 'login', 'email');

        setTimeout(() => {
          this.$store.commit('loading/SET_FALSE');
        }, 500);

        if (this.$route.query.redirect_uri) {
          this.$store.dispatch('auth/redirect', {
            redirect: this.redirect,
            queryString: this.queryString,
            query: this.$route.query,
            callback: this.callback
          });
        }
        else {
          this.$router.push({ name: 'AuthRegisterPhoneComplete', query: this.$route.query }).catch(() => {
            alert('잘못된 접근');
          });
        }

        // await this.$store.dispatch('auth/redirect');
      } catch (err) {
        this.$store.commit('loading/SET_FALSE');

        if (err === 'DROP_OUT_USER') {
          this.$store.commit('alert/ADD_ITEM', { message: this.$__t(err), status: 'error' });
          return;
        } else if (err === 'ALREADY_USED_DATA') {
          this.$store.commit('alert/ADD_ITEM', {
            message: this.$__t('Your E-mail is already in use.'),
            status: 'error'
          });
        } else {
          this.$store.commit('alert/ADD_ITEM', { message: this.$__t(err), status: 'error' });
        }
      }
    }

    // async createInviteCouponForIezUser() {
    //   if (!(this.promotion && this.campaignCode)) return;

    //   try {
    //     return await this.axios.post(
    //       this.$store.state.config.apiURL + '/v2/campaigns/invite/' + this.campaignCode,
    //       {},
    //       { headers: this.$store.state.config.userHeaders }
    //     );
    //   } catch (e) {
    //     this.$log.error(e);
    //   }
    // },

    // checkAll() {
    //   return Object.keys(this.formData).forEach((o) => (this.formData[o].value = true));
    // }
  }
};
</script>
<style lang="scss" scoped>
.title {
  font-size: unit(18);
  font-weight: 600;
}
.phoneNum-requestCert {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: unit(20);
  .btn-certify {
    width: unit(60);
    color: #2c6790;
    font-size: unit(15);
    &.inactivated {
      // opacity: 0.5;
      pointer-events: none;
      color: #c8c8c8;
    }
  }
}
.certNum-timer-resend {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: unit(20);
  .timerandresend {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: unit(100);
    gap: unit(4);
    color: #c8c8c8;
    font-size: unit(15);
    &.activated {
      color: #2c6790;
    }
    .divider {
      color: #e0e0e0;
      font-weight: 600;
      margin: 0 unit(5);
    }
  }
}
</style>
